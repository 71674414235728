import React from "react"
import { Link } from "gatsby"
import hamMenu from "../../images/ham-menu-white.svg"
import closeHamMenu from "../../images/close-ham-menu.svg"
import mainLogoWhite from "../../images/main_logo_mobile_white.svg"
import "../../../styles/header-styles.scss"

export default class MobileNav extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      close: true,
    }
  }

  hamToggle = () => {
    this.setState({
      close: !this.state.close,
    })
  }

  render() {
    const { close } = this.state
    return (
      <div>
        <div className="mobile-nav-wrapper">
          <Link to="/" className="mobile-logo-link">
            <img
              alt="mobile main logo"
              className="mobile-main-logo"
              src={mainLogoWhite}
            />
          </Link>
          <img
            onClick={() => this.hamToggle()}
            className="ham-menu-icon"
            src={hamMenu}
            alt="ham menu"
          />

          <div
            className={
              close ? "closed mobile-list-wrapper" : "open mobile-list-wrapper"
            }
          >
            <img
              onClick={() => this.hamToggle()}
              className={
                close
                  ? "closed close-ham-menu-icon"
                  : "open close-ham-menu-icon"
              }
              src={closeHamMenu}
              alt="ham menu"
            />
            <ul className="mobile-nav-list">
              <li className="mobile-nav-item">
                <Link to="/about" className="mobile-nav-link">
                  About
                </Link>
              </li>
              <li className="mobile-nav-item">
                <Link to="/services" className="mobile-nav-link">
                  Services
                </Link>
              </li>
              <li className="mobile-nav-item">
                <Link to="/gallery" className="mobile-nav-link">
                  Gallery
                </Link>
              </li>
              <li className="mobile-nav-item">
                <Link to="/bridal-boxes" className="mobile-nav-link">
                  Bridal Box
                </Link>
              </li>
              <li className="mobile-nav-item">
                <Link to="/vendors" className="mobile-nav-link">
                  Vendors
                </Link>
              </li>
              <li className="mobile-nav-item">
                <Link to="/contact" className="mobile-nav-link">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}
