import React, { Component } from "react"
import Slider from "react-slick"
import "../../styles/featured.scss"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Fade from "react-reveal/Fade"
import asSeenOne from "../images/as-seen-one.png"
import asSeenTwo from "../images/as-seen-two.png"
import asSeenThree from "../images/as-seen-three.png"
import asSeenFour from "../images/as-seen-four.png"
import asSeenFive from "../images/as-seen-five.png"
import asSeenSix from "../images/as-seen-six.png"

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: "none" }}
      onClick={onClick}
      role="figure"
    />
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: "none" }}
      onClick={onClick}
      role="figure"
    />
  )
}

export default class Featured extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 3,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 900,
      pauseOnHover: true,
    }
    return (
      <div className="featured-slide-track-wrap">
        <Fade>
          <Slider {...settings}>
            <div className="slide-wrapper-featured">
              <div>
                <a
                  className="featured-img-link"
                  href="https://wpic.ca/food-and-drink/dance-of-the-sugar-plum-fairy-a-styled-shoot/"
                >
                  <img
                    src={asSeenOne}
                    alt="WPIC logo"
                    className="as-seen-img as-seen-1"
                  />
                </a>
              </div>
            </div>
            <div className="slide-wrapper-featured">
              <div>
                <a
                  className="featured-img-link"
                  href="https://hitchededitor.wixsite.com/hitched"
                >
                  <img
                    src={asSeenTwo}
                    alt="Hitched logo"
                    className="as-seen-img as-seen-2"
                  />
                </a>
              </div>
            </div>
            <div className="slide-wrapper-featured">
              <div>
                <a
                  className="featured-img-link"
                  href="https://www.elegantwedding.ca/fairytale-wedding/"
                >
                  <img
                    src={asSeenThree}
                    alt="ELEGANT Weddings logo"
                    className="as-seen-img as-seen-3"
                  />
                </a>
              </div>
            </div>
            <div className="slide-wrapper-featured">
              <div>
                <a
                  className="featured-img-link"
                  href="https://www.weddingwire.ca/wedding-planning/yesinspired-events--e56755"
                >
                  <img
                    src={asSeenFour}
                    alt="Wedding Wire logo"
                    className="as-seen-img as-seen-4"
                  />
                </a>
              </div>
            </div>
            <div className="slide-wrapper-featured">
              <div>
                <a
                  className="featured-img-link"
                  href="https://www.ontarioweddingassociation.com/pro/20210227190421"
                >
                  <img
                    src={asSeenFive}
                    alt="OWA logo"
                    className="as-seen-img as-seen-round as-seen-5"
                  />
                </a>
              </div>
            </div>
            <div className="slide-wrapper-featured">
              <div>
                <a
                  className="featured-img-link"
                  href="https://todaysbride.ca/articles/get-your-wedding-inspiration-from-works-of-art/"
                >
                  <img
                    src={asSeenSix}
                    alt="today's bride logo"
                    className="as-seen-img as-seen-round as-seen-6"
                  />
                </a>
              </div>
            </div>
          </Slider>
        </Fade>

        <Fade>
          <div className="mobile-featured">
            <div>
              <a
                className="featured-img-link"
                href="https://wpic.ca/food-and-drink/dance-of-the-sugar-plum-fairy-a-styled-shoot/"
              >
                <img
                  src={asSeenOne}
                  alt="WPIC logo"
                  className="as-seen-img as-seen-1"
                />
              </a>
            </div>
            <div>
              <a
                className="featured-img-link"
                href="https://www.elegantwedding.ca/fairytale-wedding/"
              >
                <img
                  src={asSeenThree}
                  alt="ELEGANT Weddings logo"
                  className="as-seen-img as-seen-3"
                />
              </a>
            </div>
            <div>
              <a
                className="featured-img-link"
                href="https://www.weddingwire.ca/wedding-planning/yesinspired-events--e56755"
              >
                <img
                  src={asSeenFour}
                  alt="Wedding Wire logo"
                  className="as-seen-4"
                />
              </a>
            </div>
            <div>
              <a
                className="featured-img-link"
                href="https://www.ontarioweddingassociation.com/pro/20210227190421"
              >
                <img
                  src={asSeenFive}
                  alt="OWA logo"
                  className="as-seen-img as-seen-round as-seen-5"
                />
              </a>
            </div>
            <div>
              <a
                className="featured-img-link"
                href="https://todaysbride.ca/articles/get-your-wedding-inspiration-from-works-of-art/"
              >
                <img
                  src={asSeenSix}
                  alt="today's bride logo"
                  className="as-seen-img as-seen-round as-seen-6"
                />
              </a>
            </div>
          </div>
        </Fade>
      </div>
    )
  }
}
