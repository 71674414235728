import React from "react"
import Helmet from "react-helmet"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Header from "../components/header/home-header"
import Img from "gatsby-image"
import Testimonial from "../components/testimonial/testimonial"
import "../../styles/index.scss"
import { graphql } from "gatsby"
import Fade from "react-reveal/Fade"
import VideoBg from "reactjs-videobg"
import SEO from "../components/seo"
import Featured from "../components/featured"

const IndexPage = ({ data }) => {
  // const headerBgSources = [
  //   data.gatsbyHeaderImage.childImageSharp.fluid,
  //   {
  //     ...data.gatsbyHeaderImageMobile.childImageSharp.fluid,
  //     media: `(max-width: 1025px)`,
  //   },
  // ]

  return (
    <Layout>
      <SEO title="Event and Wedding Planning In Toronto" />
      <Helmet>
        {/*<!-- Primary Meta Tags -->*/}
        <meta
          name="description"
          content="YESInspired Events is an event planning and design company in Toronto, Ontario that has the perfect solutions, experiences, and ideas to make your celebrations extra special. From engagement parties, to corporate retreats and lavish weddings, we customize every detail to make the most of your event special. Our wedding packages include consultation, day-of coordination and design consultation."
        />

        {/*<!-- Open Graph / Facebook -->*/}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.yesinspired.ca/" />
        <meta property="og:title" content="YESinspired Events" />
        <meta
          property="og:description"
          content="YESInspired Events is an event planning and design company in Toronto, Ontario that has the perfect solutions, experiences, and ideas to make your celebrations extra special. From engagement parties, to corporate retreats and lavish weddings, we customize every detail to make the most of your event special. Our wedding packages include consultation, day-of coordination and design consultation."
        />

        {/*<!-- Twitter -->*/}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.yesinspired.ca/" />
        <meta property="twitter:title" content="YESinspired Events" />
        <meta
          property="twitter:description"
          content="YESInspired Events is an event planning and design company in Toronto, Ontario that has the perfect solutions, experiences, and ideas to make your celebrations extra special. From engagement parties, to corporate retreats and lavish weddings, we customize every detail to make the most of your event special. Our wedding packages include consultation, day-of coordination and design consultation."
        />
      </Helmet>
      <VideoBg
        loop="true"
        wrapperClass="hero-bg"
        videoClass="hero-bg"
        poster=""
      >
        <VideoBg.Source
          src="https://yesinspiredvideocontent.s3.us-east-2.amazonaws.com/video_bg.mp4"
          poster=""
          type="video/mp4"
        />
      </VideoBg>

      <div className="mobile-hero">
        <Header />

        <section className="hero">
          <Fade delay="1000">
            <div className="hero-text-wrapper">
              <h1 className=" hero-sub-heading">Get inspired</h1>
              <h2 className=" hero-heading">
                Making Your Event<br></br> Dreams a Reality
              </h2>
              <p className="text-body hero-body">
                A professional wedding and event planning service based in
                Toronto, Ontario, with connections to the best vendors and
                venues in Toronto. The YESInspired team can help plan your next
                dream event!
              </p>
              <button className="button ">
                <Link to="/services" className="button-link">
                  EXPLORE
                </Link>
              </button>
            </div>
          </Fade>
        </section>
      </div>

      <section className="about">
        <Img
          fluid={data.aboutImage.childImageSharp.fluid}
          alt="three women smiling"
          className="about-img"
        />

        <div className="about-text-wrapper">
          <Fade>
            <h1 className="sub-heading about-sub-heading">Meet the team</h1>
            <h2 className="heading about-heading">A Little About Us</h2>
          </Fade>
          <Fade>
            <p className="text-body about-body">
              YES Inspired is a professional wedding and event planning service
              based in Toronto, Ontario. The company was previously called S
              Events and Creations, founded by Stephanie Miaco. She invited her
              friends Ysabel Agaton and Edrianne Crisostomo to create what is
              now called YES Inspired.
              <br></br>
              <br></br>
              YES Inspired is meant to break barriers in the event industry
              while upholding the three main values they treasure: customer
              satisfaction, creativity, and limitless possibilities. Their
              diversity allows them to change guest’s outlook on events they’ve
              experienced before.
            </p>
          </Fade>
        </div>
      </section>

      <div className="white-bg">
        <section className="as-seen">
          <h2 className="as-seen-heading">As Featured In:</h2>

          <Featured />
          {/*
          <div className="as-seen-img-wrapper">
            <Fade>
              <a
                className="featured-img-link"
                href="https://www.weddingwire.ca/wedding-planning/yesinspired-events--e56755"
              >
                <Img
                  fluid={data.asSeenFour.childImageSharp.fluid}
                  alt="Wedding Wire logo"
                  className="as-seen-img as-seen-alt"
                />
              </a>
              <a
                className="featured-img-link"
                href="https://hitchededitor.wixsite.com/hitched"
              >
                <Img
                  fluid={data.asSeenTwo.childImageSharp.fluid}
                  alt="HITCHED logo"
                  className="as-seen-img as-seen-alt"
                />
              </a>
              <a
                className="featured-img-link"
                href="https://www.elegantwedding.ca/fairytale-wedding/"
              >
                <Img
                  fluid={data.asSeenThree.childImageSharp.fluid}
                  alt="ELEGANT Weddings logo"
                  className="as-seen-img"
                />
              </a>

              <a
                className="featured-img-link"
                href="https://wpic.ca/food-and-drink/dance-of-the-sugar-plum-fairy-a-styled-shoot/"
              >
                <Img
                  fluid={data.asSeenOne.childImageSharp.fluid}
                  alt="WPIC logo"
                  className="as-seen-img"
                />
              </a>
              <a
                className="featured-img-link"
                href="https://wpic.ca/food-and-drink/dance-of-the-sugar-plum-fairy-a-styled-shoot/"
              >
                <Img
                  fluid={data.asSeenFive.childImageSharp.fluid}
                  alt="OWA logo"
                  className=" as-seen-alt as-seen-img-rounded"
                />
              </a>
            </Fade>
          </div>
  */}
        </section>
        <section className="consult-cta">
          <Img
            fluid={data.consultImage.childImageSharp.fluid}
            alt="HITCHED logo"
            className="consult-img"
          />

          <div className="consult-text-wrapper">
            <div className="consult-text-body-wrapper">
              <Fade>
                <div className="consult-text-color">
                  <h1 className="sub-heading  consult-sub-heading">
                    A little help
                  </h1>
                  <h2 className="heading consult-heading">
                    Looking for Guidance
                  </h2>
                  <p className="text-body consult-body">
                    You have a brilliant idea for an event, but have no idea
                    where to start? No worries! We’ll help you plan and design
                    your event from start to finish. YESinspired will take care
                    of everything from finding the perfect venue to managing
                    your guest list and everything in between!
                  </p>
                </div>
              </Fade>
            </div>
            <button className="button consult-btn">
              <Link to="/contact" className="button-link">
                GET IN TOUCH
              </Link>
            </button>
          </div>
        </section>

        <section className="services">
          <div className="services-heading-wrapper">
            <h1 className=" service-title-sub-heading">Our specialty</h1>
            <h2 className=" service-title-heading">The Services</h2>
          </div>
          <div className="services-wrapper">
            {/*WEDDINGS*/}

            <div className="services-container">
              <div className="services-img-wrapper">
                <Link className="service-link" to="/services">
                  <Img
                    fluid={data.serviceImageOne.childImageSharp.fluid}
                    alt="two persons kissing in front of crowd"
                    className="services-img"
                  />
                </Link>
                <Link className="service-link" to="/services">
                  <h3 className="services-img-heading">Weddings</h3>
                </Link>
              </div>

              <h4 className="services-heading">
                Your Big Day From Start To Finish
              </h4>
              <p className="services-body-text">
                Our qualified planners are experienced in creating weddings that
                are not only beautiful, but also tailored to the needs of each
                individual couple. We pride ourselves on our commitment to
                client satisfaction.
              </p>
            </div>

            {/*EVENTS*/}

            <div className="services-container">
              <div className="services-img-wrapper">
                <Link className="service-link" to="/services">
                  <Img
                    fluid={data.serviceImageTwo.childImageSharp.fluid}
                    alt="two persons holding up glasses"
                    className="services-img"
                  />
                </Link>
                <Link className="service-link" to="/services">
                  <h3 className="services-img-heading">Events</h3>
                </Link>
              </div>

              <h4 className="services-heading">Events Made for You</h4>
              <p className="services-body-text">
                Our goal is to help you find your YES and we welcome the
                opportunity to bring our expertise and a fresh approach to your
                events. We’re entrepreneurial, resourceful, collaborative &
                inclusive. A “YESinspired” event is playful, energetic and
                memorable and is sure to engage your attendees.
              </p>
            </div>

            {/*DESIGN*/}

            <div className="services-container">
              <div className="services-img-wrapper">
                <Link className="service-link" to="/services">
                  <Img
                    fluid={data.serviceImageThree.childImageSharp.fluid}
                    alt="table decor"
                    className="services-img"
                  />
                </Link>
                <Link className="service-link" to="/services">
                  <h3 className="services-img-heading">Design</h3>
                </Link>
              </div>

              <h4 className="services-heading">
                Let your imagination run wild
              </h4>
              <p className="services-body-text">
                We work with you to help bring that desired look to life. We
                provide a variety of design styles. And because of our attention
                to details, you can be assured that your event or design will be
                handled with professional efficiency.
              </p>
            </div>

            {/*CONSULTATION*/}

            <div className="services-container">
              <div className="services-img-wrapper">
                <Link className="service-link" to="/services">
                  <Img
                    fluid={data.serviceImageFour.childImageSharp.fluid}
                    alt="two persons laughing"
                    className="services-img"
                  />
                </Link>
                <Link className="service-link" to="/services">
                  <h3 className="services-img-heading">Consultation</h3>
                </Link>
              </div>

              <h4 className="services-heading">A little guidance</h4>
              <p className="services-body-text">
                Looking for some help? Our team of planners have years of event
                experience. You will find us at the intersection of creativity,
                strategy and execution; or what we like to call - YESInspired
                Planning!
              </p>
            </div>
          </div>
        </section>
        <Fade cascade>
          <section className="testimonial">
            <Testimonial />
          </section>
        </Fade>
        <section className="gallery-preview">
          <Img
            fluid={data.featuredEventImage.childImageSharp.fluid}
            alt="two persons kissing in front of crowd"
            className="gallery-preview-img"
          />
          <div className="gallery-preview-text-wrapper">
            <Fade cascade>
              <div>
                <h1 className="sub-heading gallery-sub-heading">Take a peek</h1>
                <h2 className="heading gallery-heading">Our Latest Events</h2>
                <h3 className="gallery-preview-title">Jessica & Christian</h3>
                <p className="text-body gallery-body">
                  Jessica and Christian had their beautiful wedding ceremony at
                  a Catholic Church in North York, Ontario and a minimalist
                  modern reception at Hotel X located in Toronto, Ontario.
                </p>
              </div>
            </Fade>
            <Fade cascade>
              <button className="button index-gallery-btn">
                <Link to="/gallery" className="button-link">
                  TAKE A LOOK
                </Link>
              </button>
            </Fade>
          </div>
        </section>
        <section className="vendor">
          <div className="vendor-text-wrapper">
            <Fade>
              <h1 className="sub-heading index-vendor-sub-heading">Discover</h1>
              <h2 className="heading index-vendor-heading">Our Vendors</h2>
              <p className="text-body index-vendor-body">
                YESInspired alwasy finds the best vendors Toronto has to offer.
                To make your event a success we are always searching to find you
                the perfect florals, photographer, or decor to meet your evey
                need. Take a look at some of our favourites
              </p>
            </Fade>
            <button className="button index-vendor-btn">
              <Link to="/vendors/" className="button-link">
                EXPLORE
              </Link>
            </button>
          </div>

          <Fade>
            <div className="vendor-img">&nbsp;</div>
          </Fade>
        </section>
      </div>
    </Layout>
  )
}
export default IndexPage

export const querry = graphql`
  query {
    gatsbyHeaderImage: file(relativePath: { eq: "header-bg.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    gatsbyHeaderImageMobile: file(
      relativePath: { eq: "header-bg-mobile.png" }
    ) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    aboutImage: file(relativePath: { eq: "about-img.jpg" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    asSeenOne: file(relativePath: { eq: "as-seen-one.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    asSeenTwo: file(relativePath: { eq: "as-seen-two.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    asSeenThree: file(relativePath: { eq: "as-seen-three.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    asSeenFour: file(relativePath: { eq: "as-seen-four.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    asSeenFive: file(relativePath: { eq: "as-seen-five.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    asSeenSix: file(relativePath: { eq: "as-seen-six.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    consultImage: file(relativePath: { eq: "consult-cta-banner.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    serviceImageOne: file(relativePath: { eq: "service-weddings.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    serviceImageTwo: file(relativePath: { eq: "service-events.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    serviceImageThree: file(relativePath: { eq: "service-design.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    serviceImageFour: file(relativePath: { eq: "service-consult.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    featuredEventImage: file(relativePath: { eq: "featured-img.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    vendorImage: file(relativePath: { eq: "vendor-img.svg" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
