import { Link } from "gatsby"
import MobileNav from "./home-mobile-header"
import PropTypes from "prop-types"
import React from "react"
import mainLogoWhite from "../../images/main_logo_white.svg"
import "../../../styles/header-styles.scss"

const Header = ({ siteTitle }) => (
  <header>
    <MobileNav />
    <ul className="home-nav-list nav-list">
      <li className="home-nav-item nav-item">
        <Link to="/about" className="home-nav-link nav-link">
          About
        </Link>
      </li>
      <li className="home-nav-item nav-item">
        <Link to="/services" className="home-nav-link nav-link">
          Services
        </Link>
      </li>
      <li className="home-nav-item nav-item">
        <Link to="/gallery" className="home-nav-link nav-link">
          Gallery
        </Link>
      </li>
      <li className="home-nav-item nav-item">
        <Link to="/" className="home-nav-link nav-link">
          <img alt="main logo link" className="main-logo" src={mainLogoWhite} />
        </Link>
      </li>
      <li className="home-nav-item nav-item">
        <Link to="/bridal-boxes" className="home-nav-link nav-link">
          Bridal Box
        </Link>
      </li>
      <li className="home-nav-item nav-item">
        <Link to="/vendors" className="home-nav-link nav-link">
          Vendors
        </Link>
      </li>
      <li className="home-nav-item nav-item">
        <Link to="/contact" className="home-nav-link nav-link">
          Contact
        </Link>
      </li>
    </ul>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
