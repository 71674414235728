import React from "react"
import Slider from "react-slick"
import "../../../styles/testimonial.scss"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import quoteImg from "../../images/quote.svg"
import Fade from "react-reveal/Fade"

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: "none" }}
      onClick={onClick}
      role="figure"
    />
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: "none" }}
      onClick={onClick}
      role="figure"
    />
  )
}

export default function Testimonial() {
  var settings = {
    slidesToShow: 1,
    fade: true,
    infinite: true,
    autoplay: true,
    speed: 700,
    autoplaySpeed: 8000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    focusOnSelect: false,
    swipeToSlide: false,
  }
  return (
    <div role="figure" onClick="false" className="slider">
      <Fade>
        <div className="slide-overlay">&nbsp;</div>
        <Slider {...settings}>
          <div className="slide-wrapper">
            <img className="slide-image" alt="quote" src={quoteImg} />
            <p className="slide-quote-intro">
              "Thanks to Stephanie, and the YESinspired team me and my partner
              had the wedding of our dreams.
            </p>
            <p className="slide-quote-text">
              We were speechless with how fantastic the venue set up was. I
              would recommend them to absolutely everyone!"
            </p>
            <h3 className="slide-quote-author">Jessie & Christian</h3>
          </div>
          <div className="slide-wrapper">
            <img className="slide-image" alt="quote" src={quoteImg} />
            <p className="slide-quote-intro">
              YESInspired always answered all of my questions, and if they
              didn't know something they'd make sure to do the research and get
              back to me.
            </p>
            <p className="slide-quote-text">
              They offered plenty of ideas, helped me find amazing vendors, and
              went above and beyond to make all my wedding dreams come true!
            </p>
            <h3 className="slide-quote-author">Sarah</h3>
          </div>
          <div className="slide-wrapper">
            <img className="slide-image" alt="quote" src={quoteImg} />
            <p className="slide-quote-intro">
              I got YESInspired to design and plan an intimate event for my
              graduation party.
            </p>
            <p className="slide-quote-text">
              They were so engaged with me and supportive of my event as if they
              were planning a party for a close friend.
            </p>
            <h3 className="slide-quote-author">Abby</h3>
          </div>
        </Slider>
      </Fade>
    </div>
  )
}
